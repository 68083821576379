
/* // For modal RGPD management  */
.drawer {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* // For RGPD management  */
  .drawer-rgpd {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal-rgpd {
    position: fixed;
    font-size: 14px;
    text-align: center;
    bottom:25%;
    left:25%;
    background-color: white;
    width:50%;
    transition: ease all 1s;
    border:1px solid #CCC;
    padding: 15px;
    border-radius:20px 20px 20px 20px;
  }
  
  #img-cookie {
    width:120px;
    position:absolute;
    bottom:20px;
    left:30px;
    z-index:-1000;
  }
  
  .rgpd-button {
    margin-top:15px;
  }
  
  .rgpd-input {
    width:100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .rgpd-input span {
      width:30%;
      display:inline-block;
      font-size: 1.2em;
  }
  
  .accept-all-rgpd {
    font-size: 1.3em;
    font-weight:bold;
    // padding: 15px;
    border:none;
    color:white;
    background-color:green;
    border-radius:5px;
    width:45%;
    height:30px;
    cursor:pointer;
  }
  
  .without-accept-text {
    color:#AAA;
    float: right;
    font-size:0.8em;
    cursor:pointer;
  }
  
  .without-accept-text-sub {
    color:#AAA;
    margin-top:10px;
    font-size:0.8em;
    cursor:pointer;
  }
  
  .main-deny-button {
    font-size: 1.3em;
    padding: 15px;
    border:none;
    color:white;
    background-color:rgb(194, 194, 194);
    border-radius:5px;
    width:45%;
    height:30px;
  }
  
  .cross-rgpd {
    float:right;
    font-size:19px;
    border:1px #CCC solid;
    height:30px;
    width:30px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
  }
  
  .rgpd-sub-links {
    font-size: 0.8em;
    text-align: center;
    
  }
  
  .sub-link, .rgpd-details-link, .rgpd-details-close  {
    text-decoration: none;
    color:#888;
    cursor: pointer;
  }
  
  .sub-link:hover, .rgpd-details-link:hover, .rgpd-details-close:hover {
    text-decoration: none;
    color:#444;
  }
  
  .rgpd-button {
    margin-top:15px;
  }
  
  /* // RGPD Details modal :  */
  .drawer-rgpd-details {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal-rgpd-details {
    position: fixed;
    font-size: 14px;
    text-align: center;
    bottom:25%;
    left:25%;
    background-color: white;
    width:50%;
    transition: ease all 1s;
    border:1px solid #CCC;
    padding: 15px;
    border-radius:20px 20px 20px 20px;
    max-height:60%;
    overflow-y: auto;
  }
  
  .cookie-details-content {
    text-align: center;
    background-color: rgba(245,245,245,1);
    width:100%;
    margin-top:15px;
    padding:10px;
  }
  
  .cookie-details-content p {
      padding:10px;
  }
  
  .cookie-type-title {
    Font-size:1.1em;
    padding:10px;
    font-weight: bold;
  }
  
  .cookie-details-dropdown-title {
    margin-top:10px;
    text-decoration: underline;
    cursor:pointer;
    opacity: 0.8;
  }
  
  .cookie-details-dropdown-title:hover {
    opacity: 1;
  }
  
  .cookie-valid-partial {
    display:block;
    margin:auto;
    margin-top:5px;
    padding:5px;
    cursor:pointer;
    color:#888;
    background-color:rgba(230, 230, 230, 0.4);
    border-radius:5px;
    width:45%;
  }
  
  .cookie-valid-partial:hover {
    color:#444;
  }
  
  /* // Table cookie details management */
  .essentials-table-details,
  .statistiques-table-details,
  .marketing-table-details {
    margin-top: 15px;
    width: 100%;
    background-color: white;
    text-align: left;
    font-size:0.9em;
  }
  
  .essentials-table-details td,
  .statistiques-table-details td,
  .marketing-table-details td {
      padding:5px;
  }
  
  .essentials-table-details tr,
  .statistiques-table-details tr,
  .marketing-table-details tr {
      border-bottom:1px solid #AAA;
      border-collapse: separate;
      border-spacing: 0;
  }
  
  .table-head {
    font-weight: bold;
    width:30%;
  }
  
  .legals {
    display: none;
  }
  
  .contacts {
    display:none;
  }
  
  .open {
    display: block;
    transition: ease all 2s;
  }
  
  .hidden {
    display:none;
    transition: ease all 2s;
  }

  .hide {
    display:none;
    transition: ease all 2s;
  }

  .show {
    display:inherit;
    transition: ease all 2s;
  }
  
  .cross {
    float:right;
    border:1px #CCC solid;
    height:30px;
    width:30px;
    border-radius: 15px;
    text-align: center;
  }
  
  .cross:hover {
      cursor: pointer;
  }
  
  .no-scroll {
    overflow: hidden;
  }
  
  @media (min-width:495px) {
  
      .fix {
          position: fixed;
          top:0;
          width:50%;
          max-width: inherit;
          z-index: -1;
      }
  
      
      .unfix {
          position: relative;
      }
  }
  @media (max-width:495px) {
  
      .modal-rgpd-details {
          bottom:25%;
          left:10%;
          width: 80%;
      }
  
      .modal-rgpd {
          bottom:25%;
          left:10%;
          width: 80%;
      }
  
      #img-cookie {
      position:relative;
      padding:10px;
      margin-top:10px;
      width:40%;
      bottom:0;
      left:0;
      }
  }