@import "~bootstrap/scss/bootstrap";
@import "_fonts";

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.wrapper {
  min-width: 320px;
  font-family: 'Rubik', sans-serif;
  background: url('../images/bg.jpg') no-repeat 0 0;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

img {
  display: block;
}

.container {
  max-width: 1695px;
  margin: auto;
  justify-content: space-between;
}

.side-block {
  width: calc(50% - 15px);
}

.logo {
  margin-bottom: 50px;
  margin-top: 45px;
}

.h1 {
  font-weight: 900;
  font-size: 46px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 115%;
  margin-bottom: 25px;
}

.h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 35px;
  line-height: 100%;
  text-transform: uppercase;

  b {
    font-size: 32px;
    font-weight: 700;
    color: #ffa84b;
  }
}

.form {
  box-shadow: 0 20px 72px 8px rgba(0, 0, 1, 0.2);
  border-radius: 20px;
  margin-bottom: 60px;
  overflow: hidden;
  width: calc(100% - 30px);

  iframe {
    width: 100%;
  }
}

.exclamation {
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-right: 20px;
  border-radius: 20px;
  height: 100px;
  width: calc(50% - 45px);
  background: rgba(188, 222, 250, 0.2);

  img {
    margin: 0 30px;
  }

  p {
    color: #bbddf9;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    font-style: italic;
  }
}

.img {

  img {
    margin: -110px auto 13px;
    width: 570px;
  }
}

.list {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 55px;

  .item {
    width: 30%;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    img {
      border-radius: 45%;
      margin-right: 10px;
      box-shadow: 0 10px 20px rgba(0, 0, 1, 0.1);
    }

    span {
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

.text {
  color: #dceefd;
  font-size: 14px;
  line-height: 24px;
}

.footer {
  margin-bottom: 60px;

  .container {
    align-items: center;
  }
}

.mob-block {
  display: none;
}

@media screen and (max-width: 1800px) {
  .container {
    width: 100%;
    padding: 0 50px;
  }

  .form {
    width: 100%;
  }

  .exclamation {
    width: calc(50% - 15px);
  }
}

@media screen and (max-width: 1700px) {
  .side-block {

    &:nth-child(1) {
      width: calc(54% - 15px);
    }

    &:nth-child(2) {
      width: calc(46% - 15px);
    }
  }

  .list {

    .item {
      width: 31%;

      img {
        width: 70px;
      }

      span {
        font-size: 16px;
      }
    }
  }

  .h2 {
    font-size: 20px;

    b {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .side-block {

    &:nth-child(1) {
      width: calc(57% - 15px);
    }

    &:nth-child(2) {
      width: calc(43% - 15px);
    }
  }

  .img {

    img {
      width: 460px;
    }
  }

  .list {

    .item {
      width: 45%;

      &:nth-child(3) {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .container {
    width: 1140px;
    padding: 0;
  }

  .list {
    flex-wrap: nowrap;
    flex-direction: column;

    .item {
      width: 100%;
      margin-top: 20px;
    }
  }

  .exclamation {

    img {
      width: 35px;
      margin: 0 20px
    }

    p {
      font-size: 14px;
    }
  }

  .h2 {
    line-height: 135%;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 960px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    width: 720px;
  }

  .img {

    img {
      width: 360px;
      margin-bottom: 120px;
    }
  }

  .footer {

    .side-block {
      width: 100%;
    }
  }

  .exclamation {
    margin-bottom: 40px;
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 540px;
  }

  .logo {
    margin: 30px 0;
  }

  .h1 {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .h2 {
    font-size: 18px;
    margin-bottom: 15px;

    b {
      font-size: 24px;
    }
  }

  .mob-block {
    display: block;

    img {
      margin: auto;
    }
  }

  .btn {
    display: block;
    margin: 0 auto 15px;
    width: 300px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 25px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 21px;
    background-image: linear-gradient(to bottom, #ffb000 0%, #ff8400 100%);
    box-shadow: 0 5px 10px rgba(0, 0, 1, 0.3);
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #fff;
      box-shadow: none;
      background-image: linear-gradient(to bottom, #ff8400 0%, #ffb000 100%);
    }
  }

  .main {

    .side-block {
      width: 100%;
    }
  }

  .form {
    margin-bottom: 10px;
  }

  .img {
    display: none;
  }

  .list {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    width: 93%;
  }

  .logo {
    margin: 15px 0;

    img {
      width: 100px;
    }
  }

  .h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .h2 {
    line-height: 28px;
  }

  .exclamation {
    height: auto;
    align-items: flex-start;
    padding: 15px;

    img {
      margin-left: 0;
    }

    p {
      font-size: 17px;
    }
  }

  .footer {
    margin-bottom: 30px;
  }
}

.footer {
  .footer-mentions {
    margin-top:20px;
    justify-content: space-between;
  }

  .footer-link {
    color:white;
    text-decoration: none;
    opacity: 0.8;

    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }

  
}

.drawer-modal-mentions {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-mentions {
    position: fixed;
    font-size: 14px;
    text-align: center;
    bottom:25%;
    left:25%;
    background-color: white;
    width:50%;
    transition: ease all 1s;
    border:1px solid #CCC;
    padding: 15px;
    border-radius:20px 20px 20px 20px;
}